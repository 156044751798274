import React from 'react';
import {
  Layout,
  Actions,
  Button,
  LayoutBlock,
  Container,
  Column,
  Row,
  Section,
  Notice,
  Block,
  Hero,
  Title,
  Description,
} from '@dragonchain-dev/dragon-kit';
import { Layout as PageLayout, SEO } from '../../components/_index';

const CaseStudies = () => {
  return (
    <PageLayout>
      <SEO
        title="Case Studies by Dragonchain"
        description="Explore some of the case study solutions and applications Dragonchain developed with their partners"
      />
      <Hero type="code" backgroundImage="https://images.dragonchain.com/texture/clouds-blue@2x.jpg">
        <Title color="white">Case Studies</Title>
        <Description color="blue-light">
          <p>
            Dragonchain is uniquely capable of powering a wide variety of applications, systems, and solutions
            in full interoperability with traditional systems and software. Developers have built Emergency
            Management Systems, Learning Management Systems, influencer applications, decentralized social
            media solutions, and various complex proof systems for regulated financial markets in compliance
            with GDPR, CCPA, and HIPAA.
          </p>
          <Actions
            actions={[
              {
                name: 'Contact us',
                color: 'blue-dark',
                hover: 'blue-dark',
                to: '/contact-us',
              },
            ]}
          />
        </Description>
      </Hero>
      <LayoutBlock>
        <Container>
          <Row auto span={8} gap="medium">
            <Column>
              <Section
                title="Emergency Management System"
                subtitle="With Snohomish County"
                footer={
                  <Button blank hover="blue-dark" type="link" to="/case-studies/emergency-management-system">
                    Learn More
                  </Button>
                }
              >
                Development of a flexible Emergency Management System (EMS) for potential use ensuring
                accurate information related to inventory such as medical supplies being available in
                real-time .
              </Section>
            </Column>
          </Row>
          <Row auto span={8} gap="medium">
            <Column>
              <Section
                title="FinTech"
                subtitle="With Beaxy"
                footer={
                  <Button type="link" hover="blue-dark" to="/case-studies/fintech">
                    Learn More
                  </Button>
                }
              >
                Providing transparency and anti-fraud solutions for a US regulated financial institution.
              </Section>
            </Column>
          </Row>
          <Row auto span={8} gap="medium">
            <Column>
              <Section
                title="Supply Chain and Manufacturing"
                subtitle="With Knowledge Perk"
                footer={
                  <Button blank hover="blue-dark" type="link" to="/case-studies/supply-chain">
                    Learn More
                  </Button>
                }
              >
                Customers scan a QR code on their bag of coffee beans to see the origin story of their coffee,
                and how the exact roasting process occurred.
              </Section>
            </Column>
          </Row>
          <Row auto span={8} gap="small">
            <Column>
              <Section
                title="Decentralized Identity and Covid-19"
                subtitle="With SafePass"
                footer={
                  <Button blank hover="blue-dark" type="link" to="/case-studies/covid-19">
                    Learn More
                  </Button>
                }
              >
                In line with the FDA guidance on Digital Health Policies and Public Health Solutions for
                COVID-19.
              </Section>
            </Column>
          </Row>
          <Layout>
            <Notice>
              <p>If you have questions about any of our solutions, reach out to us today</p>
              <Button blank type="link" color="blue" hover="blue-dark" to="/contact-us">
                Contact Us
              </Button>
            </Notice>
          </Layout>
        </Container>
      </LayoutBlock>
    </PageLayout>
  );
};

export default CaseStudies;
